<template>
  <div>
    <w-top-bar>Envíos Pendientes</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="grid grid-cols-2 justify-items-stretch">
        <div class="flex gap-2 justify-self-start">
          <w-shipping-method-select
            label="Mostrar"
            emptyLabel="Métodos de envio"
            class="z-20 md:w-48"
            v-model="filterShipping"
          />
          <!-- <w-select label="Mostrar" emptyLabel="Métodos de envio" class="z-20 md:w-48" v-model="filterShipping" :options="shippingMethods" /> -->
          <w-select
            label="Mostrar"
            emptyLabel="Filtros"
            class="z-20 md:w-48"
            v-model="filter"
            :options="filterTypes"
          />
        </div>
        <div class="justify-self-end">
          <w-date-range v-model="dateFilter" @change="reload()" />
        </div>
      </div>
      <div class="grid grid-cols-2 mb-3 justify-items-stretch">
        <div class="flex justify-self-start">
          <input
            class="
              relative
              w-48
              py-2
              pl-3
              pr-10
              text-left
              transition
              duration-150
              ease-in-out
              bg-white
              border border-gray-300
              rounded-md
              cursor-default
              focus:outline-none focus:ring-blue focus:border-blue-300
              sm:text-sm sm:leading-5
            "
            type="text"
            v-model="skuFilter"
            :debounce="1000"
            name="search"
            placeholder="SKUs"
          />
        </div>
        <div class="justify-self-end">
          <div class="mt-1">
            <div class="flex gap-2">
              <button
                v-if="!isFindShipmentsPending"
                @click="dowloadCsv()"
                class="
                  relative
                  flex
                  justify-center
                  px-4
                  py-2
                  text-sm
                  font-medium
                  text-white
                  bg-indigo-600
                  border border-transparent
                  rounded-md
                  group
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <svg
                    v-if="downloading"
                    class="
                      w-5
                      h-5
                      mr-1
                      text-indigo-500
                      animate-spin
                      group-hover:text-indigo-400
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>

                  <svg
                    v-else
                    class="
                      w-5
                      h-5
                      mr-1
                      text-indigo-500
                      group-hover:text-indigo-400
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </span>
                <span class="mr-6">CSV</span>
                <div
                  class="
                    absolute
                    top-0
                    right-0
                    px-2
                    py-1
                    -mt-2
                    -mr-2
                    text-xs
                    bg-red-500
                    rounded-full
                  "
                >
                  {{ totalResults }}
                </div>
              </button>
              <button
                v-if="totalResultsAndreani > 0"
                @click="downloadAndreani()"
                class="
                  relative
                  flex
                  justify-center
                  w-32
                  px-4
                  py-2
                  text-sm
                  font-medium
                  text-white
                  bg-indigo-600
                  border border-transparent
                  rounded-md
                  group
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <svg
                    v-if="downloadingAndreani"
                    class="
                      w-5
                      h-5
                      mr-1
                      text-indigo-500
                      animate-spin
                      group-hover:text-indigo-400
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>

                  <svg
                    v-else
                    class="
                      w-5
                      h-5
                      mr-1
                      text-indigo-500
                      group-hover:text-indigo-400
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </span>
                <span class="mr-6">Andreani</span>
                <div
                  class="
                    absolute
                    top-0
                    right-0
                    px-2
                    py-1
                    -mt-2
                    -mr-2
                    text-xs
                    bg-red-500
                    rounded-full
                  "
                >
                  {{ totalResultsAndreani }}
                </div>
              </button>
            </div>
            <button
              v-if="false"
              class="
                relative
                p-2
                overflow-visible
                text-xs text-white
                bg-blue-500
                rounded
              "
            >
              <div class="flex align-center">
                <svg
                  v-if="downloading"
                  class="w-5 h-5 mr-1 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>

                <svg
                  v-else
                  class="w-6 h-6 mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
                <div>CSV</div>
              </div>
              <div
                class="
                  absolute
                  top-0
                  right-0
                  px-2
                  py-1
                  -mt-2
                  -mr-2
                  text-xs
                  bg-red-500
                  rounded-full
                "
              >
                {{ totalResults }}
              </div>
            </button>
            <!-- <a
              v-if="!isFindShipmentsPending"
              @click="dowloadCsv()"
              class="flex items-center text-sm text-blue-500 outline-none cursor-pointer btn btn-blue hover:text-blue-600"
            >

              <span>CSV</span>
              <svg
                v-if="downloading"
                class="w-6 h-6 mr-1 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              <svg
                v-else
                class="w-6 h-6 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </a> -->
            <!-- <button @click="dowloadCsv()">CSV</button> -->
          </div>
        </div>
      </div>

      <w-loading v-if="isFindShipmentsPending"></w-loading>
      <w-shipments
        v-model="status"
        @archive="doArchive"
        @downloadEtiqueta="downloadEtiqueta"
        :totalResults="totalResults"
        :patch-pending="isPatchShipmentsPending"
        :find-pending="isFindShipmentsPending"
        @nextPage="nextPage()"
        v-else
        :items="shipments"
      />
      {{ shipmentsPaginationData }}
      <w-pagination />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { makeFindMixin } from "feathers-vuex";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

const apiURL =
  process.env.NODE_ENV === "production"
    ? "https://api.orsai.org/"
    : "http://localhost:3030/";

export default {
  name: "shipmentsList",
  data() {
    return {
      skuFilterTerm: null,
      filter: "default",
      filterShipping: null,
      filterTypes: [
        {
          label: "Pendientes",
          key: "default",
          query: { archive: { $ne: true }, prelaunch: { $ne: true } },
        },
        { label: "Archivo", key: "archive", query: { archive: true } },
        {
          label: "Prelanzamiento",
          key: "prelaunch",
          query: { prelaunch: true, archive: { $ne: true } },
        },
        {
          label: "Etiqueta impresa",
          key: "printed",
          query: { printed: true },
        },
        {
          label: "Etiqueta sin imprimir",
          key: "notprinted",
          query: { printed: { $ne: true }, isAndreani: true },
        },
        { label: "Todos", key: "all", query: {} },
      ],
      shippingMethods: [],
      status: null,
      dateFilter: {
        start: moment().subtract(0, "days").startOf("day").toDate(),
        end: moment().subtract(0, "days").endOf("day").toDate(),
      },
      downloading: false,
      downloadingAndreani: false,
      archive: false,
      limit: 50,
      skip: 0,
    };
  },
  mixins: [
    makeFindMixin({
      service: "shipments",
      qid: "shipments",
      name: "shipments",
    }),
    makeFindMixin({
      service: "shipments",
      name: "andreani",
      items: "andreani",
      qid: "andreani",
    }),
  ],
  watch: {
    isArchive() {
      this.findShipments();
      this.findAndreani();
    },
    skuFilter() {
      this.findShipments();
      this.findAndreani();
    },
    filter() {
      this.updateFilter();
    },
    filterShipping() {
      this.updateFilter();
    },
    dateFilter() {
      this.clearAll();
      this.findShipments();
      this.findAndreani();
    },
  },
  computed: {
    skuFilter: {
      get() {
        return this.skuFilterTerm;
      },
      set: _.debounce(function (val) {
        console.log("changing skufilter");
        if (this.skuFilterTerm !== val) this.skuFilterTerm = val;
      }, 1000),
    },
    isPatchShipmentsPending() {
      return this.$store.state.shipments.isPatchPending;
    },
    totalResults() {
      if (this.shipmentsPaginationData.shipments)
        return this.shipmentsPaginationData.shipments.mostRecent.total;
      return 0;
    },
    totalResultsAndreani() {
      if (this.andreaniPaginationData.andreani)
        return this.andreaniPaginationData.andreani.mostRecent.total;
      return 0;
    },
    ...mapGetters("auth", ["user"]),
    isArchive() {
      return this.archive || this.$route.query.archive;
    },
    query() {
      console.log("Generating Query", this.filter, this.filterShipping);

      if (this.$route.query.orderId) {
        return {
          orderId: this.$route.query.orderId,
        };
      }

      let extraQuery = {};
      if (this.filter) {
        const queryFound = this.filterTypes.find(
          (el) => el.key === this.filter
        );
        console.log("queryFound", queryFound);
        if (queryFound) {
          extraQuery = queryFound.query;
        }
      }

      let query = {
        $limit: this.limit,
        ...extraQuery,
        createdAt: {
          $gt: moment(this.dateFilter.start).unix(),
          $lt: moment(this.dateFilter.end).unix(),
        },
        // start: this.dateFilter.start,
        // end: this.dateFilter.end,
      };

      if (this.skuFilter) {
        let skus = this.skuFilter.toUpperCase().split(",");
        query["items.sku"] = { $in: skus };
      }

      if (this.filterShipping) {
        if (this.filterShipping && this.filterShipping.length > 0)
          query["shippingMethodId"] = { $in: this.filterShipping };
        else delete query["shippingMethodId"];
      }

      console.log("query changed", query);

      return query;
    },
    countParams() {
      return {
        query: { ...this.query, $limit: 0, $sort: { createdAt: -1 } },
        // $limit: 20,
      };
    },
    shipmentsParams() {
      return {
        query: {
          ...this.query,
          // $sort: { createdAt: -1 },
          // $skip: this.skip,
        },
        $limit: 1,
      };
    },
    andreaniParams() {
      console.log("andreaniParams", this.query);
      return {
        query: {
          ...this.query,
          isAndreani: true,
        },
        $select: ["orderId", "orderIdCode", "isAndreani"],
        $limit: 0,
      };
    },
  },
  async created() {
    if (this.$route.query.q && this.$route.query.q.length > 1) {
      this.filter = this.$route.query.q;
    }

    if (this.$route.query.szId && this.$route.query.szId.length > 1) {
      this.filterShipping = this.$route.query.szId;
    }

    const shippingMethods = await this.findShippingMethods();
    this.shippingMethods = shippingMethods.data.map((sz) => {
      return {
        label: sz.company,
        sublabel: sz.name,
        key: sz._id,
        query: { shippingMethodId: sz._id },
      };
    });
  },
  methods: {
    ...mapActions("shipments", ["patch"]),
    ...mapActions("shipping-zone", { findShippingMethods: "find" }),
    ...mapMutations("shipments", ["clearAll"]),
    updateFilter() {
      if (this.filter || this.filterShipping) {
        this.$router.push({
          path: "shipments",
          query: { q: this.filter, szId: this.filterShipping },
        });
        this.clearAll();
        this.findShipments();
      }
    },
    objectToFormData(obj, form, namespace) {
      var fd = form || {};
      var formKey;

      for (var property in obj) {
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File,
        // use recursivity.
        if (typeof obj[property] === "object") {
          this.objectToFormData(obj[property], fd, property);
        } else {
          // if it's a string or a File object
          // fd.push(`${formKey}=${obj[property]}`);
          fd[formKey] = obj[property];
        }
      }

      return fd;
    },
    async doArchive(data) {
      console.log("doArchive", data);

      let query = null;
      if (data.toArchive) query = { _id: { $in: data.toArchive } };
      if (data.all) {
        query = {
          createdAt: {
            $gt: moment(this.dateFilter.start).unix(),
            $lt: moment(this.dateFilter.end).unix(),
          },
        };
      }
      if (query) {
        console.log(query);
        // await this.patch([null, { archiveTest: true }, { query }]);
        await this.$store.dispatch("shipments/patch", [
          null,
          { archive: true, archived: new Date() },
          { query },
        ]);
        console.log("READY");
        this.reload();
      }
    },
    nextPage() {
      console.log("nextPage");
      this.skip += 20;
      this.reload();
    },
    reload() {
      console.log("reload");
      this.status = "clear";
      this.findShipments();
    },
    print() {
      window.print();
    },
    async dowloadCsv() {
      console.log("dowloadCsv");
      console.log("download");

      let fileName = [
        moment(this.dateFilter.start).format("DD-MM-YY"),
        moment(this.dateFilter.end).format("DD-MM-YY"),
      ];

      if (fileName[0] == fileName[1]) fileName = fileName[0];
      else fileName = fileName.join(" - ");

      this.downloading = true;
      // eslint-disable-next-line no-unused-vars
      let extraQuery = {};
      const found = this.filterTypes.find((el) => el.key === this.filter);
      console.log("found", found);
      console.log("found", found);
      let tmp = found.query;

      if (this.skuFilter) {
        let skus = this.skuFilter.toUpperCase().split(",");
        extraQuery["items.sku"] = { $in: skus };
      }

      let date = {
        createdAt: {
          $gt: moment(this.dateFilter.start).toDate(),
          $lt: moment(this.dateFilter.end).toDate(),
        },
      };

      if (this.$route.query.orderId) {
        extraQuery = {
          orderId: this.$route.query.orderId,
        };
        tmp = {};
        date = {};
      }

      if (this.filterShipping) {
        if (this.filterShipping && this.filterShipping.length > 0)
          extraQuery["shippingMethodId"] = { $in: this.filterShipping };
        else delete extraQuery["shippingMethodId"];
      }

      // eslint-disable-next-line no-unused-vars
      let params = {
        ...tmp,
        // $limit: "-1",
        ...extraQuery,
        ...date,
      };

      console.log("PARAMS DOWNLOAD", params);

      this.axios({
        method: "post",
        url: `${apiURL}download-csv`,
        data: {
          query: params,
        },
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Envios Tienda Hernan Casciari - ${fileName}.csv`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
        });
    },
    async download() {
      console.log("download");

      let fileName = [
        moment(this.dateFilter.start).format("DD-MM-YY"),
        moment(this.dateFilter.end).format("DD-MM-YY"),
      ];

      if (fileName[0] == fileName[1]) fileName = fileName[0];
      else fileName = fileName.join(" - ");

      this.downloading = true;
      // let extraQuery = {};
      // if (this.filter === "archive") {
      //   extraQuery["archive"] = true;
      // }
      let extraQuery = {};
      let tmp = (this.filterTypes.find((el) => el.key === this.filter) || {})
        .query;

      if (this.filterShipping) {
        extraQuery["shippingMethodId"] = this.filterShipping;
      }

      let params = this.objectToFormData({
        ...tmp,
        $limit: "-1",
        ...extraQuery,
        createdAt: {
          $gt: moment(this.dateFilter.start).unix(),
          $lt: moment(this.dateFilter.end).unix(),
        },
      });

      console.log("QUERY STRING", params);
      console.log("extraQuery", extraQuery);

      this.axios
        .get(`${apiURL}shipments`, {
          responseType: "blob",
          params,
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Envios Tienda Hernan Casciari - ${fileName}.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
        });
    },
    async downloadEtiqueta(shipmentId) {
      console.log("downloadEtiqueta", shipmentId);
      let params = {
        shipments: [shipmentId],
      };

      console.log("QUERY STRING", params);
      this.axios
        .post(
          `${apiURL}andreani-etiquetas`,
          { params },
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          }
        )
        .then((response) => {
          console.log("response.data", response.data);
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Andreani-${shipmentId}.pdf`;
          link.click();
          this.downloadingAndreani = false;
        })
        .catch(() => {
          this.downloadingAndreani = false;
        });
    },
    async downloadAndreani() {
      console.log("downloadAndreani");

      let fileName = [
        moment(this.dateFilter.start).format("DD-MM-YY"),
        moment(this.dateFilter.end).format("DD-MM-YY"),
      ];

      if (fileName[0] == fileName[1]) fileName = fileName[0];
      else fileName = fileName.join(" - ");

      this.downloadingAndreani = true;

      let extraQuery = {};
      let tmp = (this.filterTypes.find((el) => el.key === this.filter) || {})
        .query;

      let params = {
        ...tmp,
        ...extraQuery,
        createdAt: {
          $gt: moment(this.dateFilter.start).unix(),
          $lt: moment(this.dateFilter.end).unix(),
        },
      };

      console.log("QUERY STRING", params);
      console.log("extraQuery", extraQuery);

      this.axios
        .post(
          `${apiURL}andreani-etiquetas`,
          { params },
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          }
        )
        .then((response) => {
          console.log("response.data", response.data);
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Andreani-${fileName}.pdf`;
          link.click();
          this.downloadingAndreani = false;
        })
        .catch(() => {
          this.downloadingAndreani = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
