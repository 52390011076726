import { render, staticRenderFns } from "./index-old.vue?vue&type=template&id=5f6fd4df&scoped=true&"
import script from "./index-old.vue?vue&type=script&lang=js&"
export * from "./index-old.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f6fd4df",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WDateRange from '@/components/WDateRange.vue'
import WLoading from '@/components/WLoading.vue'
import WPagination from '@/components/WPagination.vue'
import WSelect from '@/components/WSelect.vue'
import WShipments from '@/components/WShipments.vue'
import WShippingMethodSelect from '@/components/WShippingMethodSelect.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WDateRange,WLoading,WPagination,WSelect,WShipments,WShippingMethodSelect,WTopBar})
